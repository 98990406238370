import type { BackendSessionActions } from './backendSession';
import type { BackendSessionJsonRpcActions } from './backendSessionJsonRpc';
import type { BootActions } from './boot';
import type { ChapterActions } from './chapter';
import type { CodeExplanationActions } from './codeExplanation';
import type { ContentAuthorizationStatusActions } from './contentAuthorization';
import type { CourseAction } from './course';
import type { DatawarehouseSessionActions } from './datawarehouseSession';
import type { ExerciseActions } from './exercises';
import type { ExternalExerciseActions } from './externalExercise';
import type { LocationActions } from './location';
import type { SetWorkspaceNotesAction } from './notes';
import type { OnboardingMilestonesActions } from './onboardingMilestones';
import type { OutputActions } from './output';
import type { SettingActions } from './settings';
import type { StreakActions } from './streakInfo';
import type { UserAction } from './user';
import type { WindowActions } from './window';

export * from './backendSession';
export * from './backendSessionJsonRpc';
export * from './datawarehouseSession';
export * from './boot';
export * from './chapter';
export * from './course';
export * from './exercises';
export * from './location';
export * from './output';
export * from './settings';
export * from './systemStatus';
export * from './user';
export * from './externalExercise';
export * from './window';
export * from './mobilePopup';
export * from './onboardingMilestones';
export * from './contentAuthorization';
export * from './dashboard';
export * from './notes';
export * from './codeSubmission';
export * from './codeExplanation';
export * from './streakInfo';
export * from './courseReview';

export const noopAction = { type: 'noop' } as const;
type NoopAction = typeof noopAction;

export const emptyAction = {} as Action;

export type Action =
  | BackendSessionActions
  | BackendSessionJsonRpcActions
  | BootActions
  | ChapterActions
  | CodeExplanationActions
  | ContentAuthorizationStatusActions
  | CourseAction
  | DatawarehouseSessionActions
  | ExerciseActions
  | ExternalExerciseActions
  | LocationActions
  | NoopAction
  | OnboardingMilestonesActions
  | OutputActions
  | SettingActions
  | SetWorkspaceNotesAction
  | StreakActions
  | UserAction
  | WindowActions;
