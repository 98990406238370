export const UPDATE_CHAPTER = 'chapter/UPDATE_CHAPTER';
export type UpdateChapterAction = {
  chapterRef: number | string;
  chapters: any;
  type: typeof UPDATE_CHAPTER;
};
export const updateChapter = ({
  chapterRef,
  chapters,
}: {
  chapterRef: number | string;
  chapters: any;
}): UpdateChapterAction => ({
  type: UPDATE_CHAPTER,
  chapterRef,
  chapters,
});

export const SHOULD_SHOW_WINDOW = 'chapter/SHOULD_SHOW_WINDOW';
export type ShouldShowWindowAction = {
  isCourseEndReached?: boolean;
  meta?: any;
  type: typeof SHOULD_SHOW_WINDOW;
  url?: string;
  windowType: string;
};
export const shouldShowWindow = ({
  isCourseEndReached,
  meta,
  type,
  url,
}: {
  isCourseEndReached?: boolean;
  meta?: any;
  type: string;
  url?: string;
}): ShouldShowWindowAction => ({
  type: SHOULD_SHOW_WINDOW,
  windowType: type,
  url,
  meta,
  isCourseEndReached,
});

export type ChapterActions = ShouldShowWindowAction | UpdateChapterAction;
