export const EPIC_GOT_WINDOW_RESPONSE = 'window/EPIC_GOT_WINDOW_RESPONSE';
export type EpicGotWindowResponseAction = {
  type: typeof EPIC_GOT_WINDOW_RESPONSE;
};
export const epicGotWindowResponse = (): EpicGotWindowResponseAction => ({
  type: EPIC_GOT_WINDOW_RESPONSE,
});

export const SHOW_WINDOW = 'window/SHOW_WINDOW';
export type ShowWindowAction = {
  show: boolean;
  type: typeof SHOW_WINDOW;
};
export const showWindow = ({
  show,
}: Pick<ShowWindowAction, 'show'>): ShowWindowAction => ({
  type: SHOW_WINDOW,
  show,
});

export const REMOVE_WINDOW = 'window/REMOVE_WINDOW';
export type RemoveWindowAction = {
  type: typeof REMOVE_WINDOW;
};
export const removeWindow = (): RemoveWindowAction => ({ type: REMOVE_WINDOW });

export type WindowActions =
  | EpicGotWindowResponseAction
  | RemoveWindowAction
  | ShowWindowAction;
