import type {
  Command,
  Language as MultiplexerClientLanguage,
  SessionOutput,
  SessionStatusCode,
} from '@datacamp/multiplexer-client';
import type { Language as JsonRpcClientLanguage } from '@datacamp/multiplexer-client-jsonrpc';

export const NO_SESSION = 'backendSession/NO_SESSION';
export type INoSessionAction = {
  type: typeof NO_SESSION;
};
export const noSession = (): INoSessionAction => ({ type: NO_SESSION });

export const EPIC_START_SESSION = 'backendSession/EPIC_START_SESSION';
export type IEpicStartSessionAction = {
  force_new: boolean;
  id: number; // exercise id
  initCommandOptions: Partial<Command>;
  language: JsonRpcClientLanguage | MultiplexerClientLanguage;
  timestamp: number;
  type: typeof EPIC_START_SESSION;
};
export const epicStartSession = ({
  force_new: forceNew = false,
  id,
  language,
  timestamp = Date.now(),
  ...initCommandOptions
}: Omit<IEpicStartSessionAction, 'initCommandOptions' | 'type'> &
  Partial<Command>): IEpicStartSessionAction => ({
  type: EPIC_START_SESSION,
  timestamp,
  language,
  id,
  force_new: forceNew,
  initCommandOptions,
});

export const EPIC_SUBMIT_CODE = 'backendSession/EPIC_SUBMIT_CODE';
export type SubmitCodeSettings = Command & {
  correct: boolean;
  id: number;
  language: JsonRpcClientLanguage | MultiplexerClientLanguage;
  message: string;
  xp: number;
};
export type IEpicSubmitCodeAction = {
  height?: number;
  settings: Partial<SubmitCodeSettings>;
  timestamp: number;
  type: typeof EPIC_SUBMIT_CODE;
  width?: number;
};
export type IEpicSubmitCodeActionArgs = Partial<SubmitCodeSettings> & {
  height?: number;
  timestamp?: number;
  width?: number;
};
export const epicSubmitCode = ({
  height,
  timestamp = Date.now(),
  width,
  ...commandConfig
}: IEpicSubmitCodeActionArgs): IEpicSubmitCodeAction => ({
  type: EPIC_SUBMIT_CODE,
  settings: commandConfig,
  timestamp,
  width,
  height,
});

export const EPIC_CLEAR_BACKEND_CLIENT_SETTINGS =
  'backendSession/EPIC_CLEAR_BACKEND_CLIENT_SETTINGS';
export type IEpicClearBackendClientSettingsAction = {
  type: typeof EPIC_CLEAR_BACKEND_CLIENT_SETTINGS;
};
export const epicClearBackendClientSettings =
  (): IEpicClearBackendClientSettingsAction => ({
    type: EPIC_CLEAR_BACKEND_CLIENT_SETTINGS,
  });

export const EPIC_BACKEND_ERROR = 'backendSession/EPIC_BACKEND_ERROR';
export type IEpicBackendErrorAction = {
  output: SessionOutput;
  type: typeof EPIC_BACKEND_ERROR;
};
export const epicBackendError = (
  output: SessionOutput,
): IEpicBackendErrorAction => ({
  type: EPIC_BACKEND_ERROR,
  output,
});

export const EPIC_MUX_REGISTERED = 'backendSession/EPIC_MUX_REGISTERED';
export type IEpicMuxRegisteredAction = {
  type: typeof EPIC_MUX_REGISTERED;
};
export const epicMuxRegistered = (): IEpicMuxRegisteredAction => ({
  type: EPIC_MUX_REGISTERED,
});

export const UPDATE_BACKEND_STATUS = 'backendSession/UPDATE_BACKEND_STATUS';
export type IUpdateBackendStatusAction = {
  message: string | null | undefined;
  status: SessionStatusCode;
  statusCode?: number;
  type: typeof UPDATE_BACKEND_STATUS;
};
export const updateBackendStatus = ({
  message,
  status,
  statusCode,
}: Omit<IUpdateBackendStatusAction, 'type'>): IUpdateBackendStatusAction => ({
  type: UPDATE_BACKEND_STATUS,
  status,
  statusCode,
  message,
});

export const EPIC_UPDATE_BACKEND_STATUS =
  'backendSession/EPIC_UPDATE_BACKEND_STATUS';
export type IEpicUpdateBackendStatusAction = {
  error?: Error;
  message?: string | null;
  status: SessionStatusCode;
  statusCode?: number;
  timestamp?: number;
  type: typeof EPIC_UPDATE_BACKEND_STATUS;
};
export const epicUpdateBackendStatus = ({
  error,
  message,
  status,
  statusCode,
  timestamp = Date.now(),
}: Omit<
  IEpicUpdateBackendStatusAction,
  'type'
>): IEpicUpdateBackendStatusAction => ({
  type: EPIC_UPDATE_BACKEND_STATUS,
  status,
  statusCode,
  timestamp,
  message,
  error,
});

export const ON_CODE_SUBMITTED = 'backendSession/ON_CODE_SUBMITTED';
export type IOnCodeSubmittedAction = {
  language: JsonRpcClientLanguage | MultiplexerClientLanguage;
  timestamp: number;
  type: typeof ON_CODE_SUBMITTED;
};
export const onCodeSubmitted = ({
  language,
  timestamp,
}: Omit<IOnCodeSubmittedAction, 'type'>): IOnCodeSubmittedAction => ({
  type: ON_CODE_SUBMITTED,
  timestamp,
  language,
});

export const SET_SESSION_ID = 'backendSession/SET_SESSION_ID';
export type ISetSessionId = {
  sessionId: string;
  type: typeof SET_SESSION_ID;
};
export const setSessionId = (
  action: Omit<ISetSessionId, 'type'>,
): ISetSessionId => ({
  ...action,
  type: SET_SESSION_ID,
});

export type BackendSessionActions =
  | IEpicBackendErrorAction
  | IEpicClearBackendClientSettingsAction
  | IEpicMuxRegisteredAction
  | IEpicStartSessionAction
  | IEpicSubmitCodeAction
  | IEpicUpdateBackendStatusAction
  | INoSessionAction
  | IOnCodeSubmittedAction
  | ISetSessionId
  | IUpdateBackendStatusAction;
