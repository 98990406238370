export const SUBMIT_EXTERNAL_EXERCISE =
  'externalExercise/SUBMIT_EXTERNAL_EXERCISE';
export const submitExternalExercise = ({
  code,
  correct,
  exerciseType,
  message,
  xp,
}: Omit<
  SubmitExternalExerciseAction,
  'type'
>): SubmitExternalExerciseAction => ({
  type: SUBMIT_EXTERNAL_EXERCISE,
  message,
  code,
  correct,
  exerciseType,
  xp,
});
export type SubmitExternalExerciseAction = {
  code: string;
  correct: boolean;
  exerciseType?: string;
  message: string;
  type: typeof SUBMIT_EXTERNAL_EXERCISE;
  xp?: number;
};

export const UPDATE_EXTERNAL_EXERCISE_XP =
  'externalExercise/UPDATE_EXTERNAL_EXERCISE_XP';

export type UpdateExternalExerciseXpAction = {
  currentXp: number;
  type: typeof UPDATE_EXTERNAL_EXERCISE_XP;
};
export const updateExternalExerciseXp = ({
  currentXp,
}: {
  currentXp: number;
}): UpdateExternalExerciseXpAction => ({
  type: UPDATE_EXTERNAL_EXERCISE_XP,
  currentXp,
});

export const NEXT_EXTERNAL_EXERCISE = 'externalExercise/NEXT_EXTERNAL_EXERCISE';
export const nextExternalExercise = (): NextExternalExerciseAction => ({
  type: NEXT_EXTERNAL_EXERCISE,
});
export type NextExternalExerciseAction = {
  type: typeof NEXT_EXTERNAL_EXERCISE;
};

export type ExternalExerciseActions =
  | NextExternalExerciseAction
  | SubmitExternalExerciseAction
  | UpdateExternalExerciseXpAction;
